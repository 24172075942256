import { Controller } from '@hotwired/stimulus'
import Tagify from '@yaireo/tagify'

const ZERO_WIDTH_UNICODE_CHAR = '\u200B'

export default class extends Controller {
  static targets = ['input', 'preview']

  connect() {
    this.initializeIconPicker()
    this.updatePreview()
  }

  initializeIconPicker() {
    const iconList = JSON.parse(this.inputTarget.dataset.iconList)

    this.tagify = new Tagify(this.inputTarget, {
      enforceWhitelist: true,
      mode: 'select',
      whitelist: iconList,
      tagTextProp: 'label',
      dropdown: {
        maxItems: Infinity,
        enabled: 0,
        closeOnSelect: true,
      },
      templates: {
        wrapper(input, _s) {
          return `<tags class="${_s.classNames.namespace} ${
            _s.mode ? `${_s.classNames[_s.mode + 'Mode']}` : ''
          } ${input.className} w-full"
                    ${_s.readonly ? 'readonly' : ''}
                    ${_s.disabled ? 'disabled' : ''}
                    ${_s.required ? 'required' : ''}
                    ${_s.mode === 'select' ? "spellcheck='false'" : ''}
                    tabIndex="-1">
                    ${this.settings.templates.input.call(this)}
                ${ZERO_WIDTH_UNICODE_CHAR}
        </tags>`
        },

        input() {
          var _s = this.settings,
            placeholder = _s.placeholder || ZERO_WIDTH_UNICODE_CHAR

          return `<span ${
            !_s.readonly && _s.userInput ? 'contenteditable' : ''
          } tabIndex="0" data-placeholder="${placeholder}" aria-placeholder="${
            _s.placeholder || ''
          }"
                    class="${_s.classNames.input}"
                    role="textbox"
                    autocapitalize="false"
                    autocorrect="off"
                    spellcheck="false"
                    aria-autocomplete="both"
                    aria-multiline="${_s.mode == 'mix' ? true : false}">
HERE</span>`
        },

        dropdownItem(item) {
          return `<div ${this.getAttributes(item)}
                    class='${this.settings.classNames.dropdownItem} ${
            this.isTagDuplicate(item.value)
              ? this.settings.classNames.dropdownItemSelected
              : ''
          } ${item.class || ''}'
                    tabindex="0"
                    role="option">
            <span>${item.label}</span>
          </div>`
        },
      },
    })

    this.tagify.on('change', this.handleChange.bind(this))
  }

  handleChange(e) {
    this.updatePreview()
  }

  updatePreview() {
    const selectedValue = this.inputTarget.value
    if (selectedValue) {
      const iconData = JSON.parse(selectedValue)[0]
      this.previewTarget.innerHTML = `<i class="fas ${iconData.value} text-xl"></i>`
    } else {
      this.previewTarget.innerHTML = null
    }
  }
}
