import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['item', 'template', 'wrapper']

  connect() {
    this.indexCounter = this.itemTargets.length
    this.updatePositions()
    this.updateMoveButtons()
  }

  add(event) {
    event.preventDefault()
    const content = this.templateTarget.content.cloneNode(true)
    this.updateCloneIndices(content)
    this.wrapperTarget.appendChild(content)
    this.indexCounter++
    this.updatePositions()
    this.updateMoveButtons()
  }

  remove(event) {
    event.preventDefault()
    const item = event.target.closest(
      '[data-form--nested-builder-target="item"]'
    )
    const destroyField = item.querySelector('input[name$="[_destroy]"]')
    if (destroyField) {
      destroyField.value = '1'
      item.style.display = 'none'
    } else {
      item.remove()
    }
    this.updatePositions()
    this.updateMoveButtons()
  }

  moveUp(event) {
    event.preventDefault()
    const item = event.target.closest(
      '[data-form--nested-builder-target="item"]'
    )
    const previousItem = item.previousElementSibling
    if (previousItem) {
      this.animateSwap(item, previousItem, true)
    }
  }

  moveDown(event) {
    event.preventDefault()
    const item = event.target.closest(
      '[data-form--nested-builder-target="item"]'
    )
    const nextItem = item.nextElementSibling
    if (nextItem) {
      this.animateSwap(item, nextItem, false)
    }
  }

  animateSwap(item1, item2, isMovingUp) {
    const height1 = item1.offsetHeight
    const height2 = item2.offsetHeight
    const move1 = isMovingUp ? -height2 : height2
    const move2 = isMovingUp ? height1 : -height1

    // Set initial positions
    item1.style.transform = `translateY(0)`
    item2.style.transform = `translateY(0)`

    // Force a reflow
    void item1.offsetWidth

    // Apply transitions
    item1.style.transition = `transform 300ms ease-in-out`
    item2.style.transition = `transform 300ms ease-in-out`

    // Move items
    item1.style.transform = `translateY(${move1}px)`
    item2.style.transform = `translateY(${move2}px)`

    // After animation completes
    setTimeout(() => {
      // Remove transitions and transforms
      item1.style.transition = ''
      item2.style.transition = ''
      item1.style.transform = ''
      item2.style.transform = ''

      // Actually move the items in the DOM
      if (isMovingUp) {
        item1.parentNode.insertBefore(item1, item2)
      } else {
        item1.parentNode.insertBefore(item2, item1)
      }

      this.updatePositions()
      this.updateMoveButtons()
    }, 300)
  }

  updateCloneIndices(content) {
    content.querySelectorAll('input, select, textarea').forEach((el) => {
      const name = el.getAttribute('name')
      if (name) {
        el.setAttribute('name', name.replace('NEW_RECORD', this.indexCounter))
      }
      const id = el.getAttribute('id')
      if (id) {
        el.setAttribute('id', id.replace('NEW_RECORD', this.indexCounter))
      }
    })
  }

  updatePositions() {
    this.itemTargets.forEach((item, index) => {
      const positionField = item.querySelector('input[name$="[position]"]')
      if (positionField) {
        positionField.value = index + 1
      }
      item.dataset.position = index + 1
    })
  }

  updateMoveButtons() {
    const visibleItems = this.itemTargets.filter(
      (item) => item.style.display !== 'none'
    )
    visibleItems.forEach((item, index) => {
      const upButton = item.querySelector('[data-action$="moveUp"]')
      const downButton = item.querySelector('[data-action$="moveDown"]')

      if (upButton) {
        upButton.style.display = index === 0 ? 'none' : ''
      }

      if (downButton) {
        downButton.style.display =
          index === visibleItems.length - 1 ? 'none' : ''
      }
    })
  }
}
